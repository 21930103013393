<template>
  <v-card
    light
    flat
    outlined
  >
    <v-card-title class="text-h5 lighten-3 pb-2 pt-2">
      {{ label }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-select
            v-model="typeItem"
            item-value="id"
            item-text="label"
            :items="[{id: 'persons', label: 'Person'}, {id: 'organizations', label: 'Organizations'}, {id: 'groups', label: 'Groups'}]"
            label="What kind of entry you d like to create?"
          />
        </v-col>
        <v-col
          v-if="typeItem === 'persons'"
          cols="12"
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="editedItem.firstName"
            label="First name"
          />
        </v-col>
        <v-col
          v-if="typeItem === 'persons'"
          cols="12"
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="editedItem.lastName"
            label="Last name"
          />
        </v-col>
        <v-col
          v-if="typeItem !== 'persons'"
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-model="editedItem.name"
            :label="typeItem !== 'groups' ? 'Organization name' : 'Group name'"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-model="editedItem.web"
            label="web"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="grey"
        @click="cancel"
      >
        Cancel
        <v-icon right>
          mdi-close-circle
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        color="green ml-auto mr-0"
        @click="save"
      >
        Create a entry of {{ typeItem }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  export default {
    name: 'CreateAndEditEntity',

    props: {
      subheading: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: 'HGK database',
      },
      itemType: {
        type: String,
        default: 'persons',
      },
      item: {
        type: Object,
        default: () => {},
      },
      text: {
        type: String,
        default: '',
      },
      search: {
        type: Object,
        default: () => {
          return {
            query: '',
            page: 0,
            size: 50,
          }
        },
      },
    },
    data () {
      return {
        editedIndex: -1,
        typeItem: 'persons',
        editedItem: {
          id: '',
          firstName: '',
          lastName: '',
          name: '',
          web: '',
        },
        defaultItem: {
          firstName: '',
          lastName: '',
          name: '',
          web: '',
        },
        dialog: false,
        entitySearch: '',
        dialogDelete: false,
      }
    },
    created () {
      if (this.item) {
        this.editedItem = this.item
        this.editedIndex = 1
      }
      if (this.itemType) {
        this.typeItem = this.itemType
      }
    },
    methods: {
      async save () {
        if (this.typeItem === 'persons') {
          delete this.editedItem.name
        } else if (this.typeItem === 'organizations') {
          delete this.editedItem.firstName
          delete this.editedItem.lastName
        }
        this.editedItem.type = this.typeItem
        if (this.editedIndex > -1) {
          Object.assign(this.entries[this.editedIndex], this.editedItem)
          await this.$store.dispatch('updateEntity', this.editedItem).finally(() => {
            this.initialize()
          })
        } else {
          await this.$store.dispatch('createEntity', this.editedItem).finally(() => {
            if (this.$parent.searching !== undefined && !this.$parent.searching) {
              this.$parent.searching = true
            }
          })
        }
      },
      cancel () {
        if (this.$parent.searching !== undefined && !this.$parent.searching) {
          this.$parent.searching = true
        }
      },
    },
  }
</script>
