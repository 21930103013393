<template>
  <v-card
    v-if="searching"
    flat
    light
    outlined
    class="mb-5"
  >
    <v-card-text>
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="searchInput"
        chips
        deletable-chips
        menu-props="closeOnContentClick"
        hide-selected
        item-text="displayName"
        :label="label"
        prepend-icon="mdi-database-search"
        return-object
        :counter="count"
        multiple
      >
        <template #append-item>
          <v-card-actions>
            <v-btn
              color="blue"
              @click="searching = !searching"
            >
              Add entry
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              :disabled="!model"
              color="grey darken-3"
              class="ml-auto mr-0"
              @click="model = null"
            >
              Clear
              <v-icon right>
                mdi-close-circle
              </v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-autocomplete>
    </v-card-text>
    <!--    <v-expand-transition>
      <v-list
        v-if="model"
      >
        <v-list-item
          v-for="(field, i) in fields"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title v-text="field.value.displayName" />
            <v-list-item-subtitle v-text="field.value.references" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>-->
  </v-card>
  <createAndEditEntity v-else />
</template>
<script>
  import { mapState } from 'vuex'
  import createAndEditEntity from './CreateAndEditEntity'
  export default {
    name: 'SelectEntity',
    components: { createAndEditEntity },
    props: {
      subheading: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: 'HGK database',
      },
      text: {
        type: String,
        default: '',
      },
      loadedSelection: {
        type: Array,
        default: () => [],
      },
      search: {
        type: Object,
        default: () => {
          return {
            query: '',
            page: 0,
            size: 50,
          }
        },
      },
    },
    data: () => ({
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      searching: true,
      typeItem: undefined,
      model: null,
      count: 0,
      searchInput: '',
    }),
    computed: {
      ...mapState({
        searchResults: state => state.search.result.items || [],
        searchTotalCount: state => state?.search.result.total || 0,
      }),
      fields () {
        if (!this.model) return []
        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          return entry
        })
      },
    },

    watch: {
      searchInput (val) {
        // Items have already been requested
        // if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return
        this.isLoading = true
        const query = {
          title: val,
          page: this.search.page,
          size: this.search.size,
        }

        this.$store.dispatch('search/get', query)
          .then(() => {
            const items = this.searchResults
            this.count = this.searchTotalCount
            this.entries = items
          }).finally(() => {
            this.isLoading = false
          })
      },
      model (n) {
        this.setSelect(n)
      },
      loadedSelection (n) {
        this.model = n
      },
    },
    updated () {
      if (this.loadedSelection.length > 0) {
        this.model = this.loadedSelection
        console.log(this.model, this.entries)
      }
    },
    created () {
      if (this.itemType) {
        this.typeItem = this.itemType
      }
    },
    methods: {
      setSelect () {
        this.$emit('selection', this.model)
      },
    },
  }
</script>
