<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row v-if="!!itemCurrent">
      <v-col
        cols="12"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template
            v-if="itemCurrent.metadata"
            #heading
          >
            <div class="text-h3 font-weight-light">
              {{ itemCurrent.metadata.name }}
            </div>

            <div
              v-if="itemCurrent.metadata && itemCurrent.metadata.description"
              class="text-subtitle-1 font-weight-light"
            >
              {{ itemCurrent.metadata.description }}
            </div>
            <v-dialog
              :key="reloadKey"
              v-model="dialog"
              max-width="1240"
              persistent
            >
              <template #activator="{ on, attrs }">
                <v-row>
                  <v-col
                    offset-md="6"
                    md="3"
                    cols="12"
                  >
                    <v-select
                      v-model="editedItem.template"
                      item-value="id"
                      item-text="label"
                      class="mb-0 mt-5 mt-md-0"
                      dense
                      :items="templates"
                      outlined
                      color="light"
                      label="What kind of object you d like to create?"
                    />
                  </v-col>
                  <v-col md="3">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      :disabled="!editedItem.template"
                      v-on="on"
                      @click.native="delete editedItem.id"
                    >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Create {{ editedItem.template }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <v-card class="modal-wrapper">
                <v-toolbar
                  color="warning"
                  flat
                  class="px-0"
                >
                  <h3>{{ editedItem.id ? templates.filter(e => e.id === editedItem.template)[0].label + ' bearbeiten' : editedItem.template + ' erstellen' }}</h3> <br>
                  <v-btn
                    color="dark"
                    text
                    right
                    class="ml-auto"
                    @click="close"
                  >
                    X
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-form ref="form">
                    <schema-from
                      v-if="dialog && editedItem"
                      :template="`/templates/${editedItem.template}.json`"
                      :ui="`/templates/ui.${editedItem.template}.json`"
                      :loaded-selection="editedItem"
                      @update="updateInput"
                    >
                      <template #default="item">
                        <template v-if="item.props.name === 'mentors'">
                          <br>
                          <selectEntity
                            style="flex: 1 0 100%"
                            :label="item.props.label"
                            item-type="persons"
                            :loaded-selection="editedItem.mentors"
                            @selection="setSelection($event, 'mentors')"
                          />
                        </template>
                        <template v-else-if="item.props.name === 'authors'">
                          <br>
                          <selectEntity
                            style="flex: 1 0 100%"
                            :label="item.props.label"
                            item-type="persons"
                            :loaded-selection="editedItem.authors"
                            @selection="setSelection($event, 'authors')"
                          />
                        </template>
                        <template v-else-if="item.props.name === 'uploads'">
                          <br>
                          <upload
                            style="flex: 1 0 100%"
                            :label=" item.props.label || 'Uploads'"
                            :value="editedItem.uploads"
                            @hasUploads="setHasPendingUploads"
                            @selection="setSelection($event, 'uploads')"
                          />
                        </template>
                        <template v-else>
                          {{ item.props }}
                        </template>
                      </template>
                    </schema-from>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="hasPendingUpload"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <v-card-text class="px-0">
            <v-data-table
              v-if="!$route.query.object"
              :headers="headers"
              :items="objectList"
              class="elevation-4 mb-4"
            >
              <!--              :options.sync="pagination"-->
              <!--              :server-items-length="totalEntries"-->
              <!--              :loading="loading"-->
              <template #top>
                <v-toolbar
                  flat
                  height="120"
                  min-height="120"
                >
                  <v-row class="my-10">
                    <v-col
                      class="pa-0"
                      cols="12"
                      sm="12"
                      md="6"
                      align-self="end"
                      style="text-align: right"
                    >
                      <v-dialog
                        v-model="dialogDelete"
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-title class="text-h5">
                            Are you sure you want to delete <b>{{ editedItem.displayName }}</b> item?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="error"
                              @click="deleteItemConfirm"
                            >
                              Yes, delete
                            </v-btn>
                            <v-spacer />
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template
                slot="items"
                slot-scope="props"
              >
                <td>{{ props.item.displayName }}</td>
                <td class="text-right">
                  {{ props.item.web }}
                </td>
                <td class="text-right">
                  {{ props.item.iron }}
                </td>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.authors="{ item }">
                <template v-if="item.authors && item.authors.length > 0">
                  <div
                    v-for="(au, ax) in item.authors"
                    :key="ax"
                    class="author pr-2"
                    style="display: inline-block"
                  >
                    <v-icon
                      small
                      class="mr-2"
                    >
                      mdi-account
                    </v-icon>
                    {{ au.firstName }} {{ au.lastName }}
                  </div>
                </template>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="showItem(item)"
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <template v-else-if="$route.query.object">
              <v-icon @click="$router.push({query: null})">
                mdi-arrow-left
              </v-icon>
              <pre>
                {{ objectCurrent }}
              </pre>
            </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import projectTemplate from '../../../public/templates/project.json'
  import thesisTemplate from '../../../public/templates/thesis.json'
  import diplomTemplate from '../../../public/templates/diploma.json'
  import { mapState } from 'vuex'
  import SchemaFrom from '@/components/SchemaFrom.vue'
  import selectEntity from '@/components/widgets/SelectEntity'
  import Upload from '@/components/widgets/Upload'
  import Vue from 'vue'

  export default {
    name: 'DashboardNodes',
    components: { Upload, selectEntity, SchemaFrom },
    computed: {
    ...mapState({
      itemCurrent: state => state.currentNode || undefined,
      objectCurrent: state => state.currentObject || undefined,
      objectList: state => state.objects || [],
      searchResults: state => state.search.result.items || [],
      searchTotalCount: state => state?.search.result.total || 0,
    }),
    params (nv) {
      // console.log(nv, 'params')
      return {
        ...this.pagination,
        query: this.search,
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    },
    data () {
      return {
        list: {
          0: false,
          1: false,
          2: false,
        },
        hasPendingUpload: false,
        reloadKey: 1,
        search: '',
        totalEntries: 0,
        entries: [],
        loading: true,
        pagination: {},
        templates: [
          { id: 'thesis', label: 'Thesis', value: thesisTemplate },
          {
            id: 'project',
            label: 'Project',
            value: projectTemplate,
          },
          { id: 'diploma', label: 'Diploma', value: diplomTemplate },

        ],
        headers: [
          // { text: 'ID', value: 'id', align: 'start', sortable: false },
          {
            text: 'Display Name',
            align: 'left',
            sortable: false,
            value: 'displayName',
          },
          { text: 'Authors', value: 'authors', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        editedIndex: -1,
        editedItem: {},
        defaultItem: {},
        dialog: false,
        dialogDelete: false,
        currentObjectId: null,
        licenses: [
          { label: 'CC-BY', value: 'CC-BY' },
          { label: 'CC-BY-NC', value: 'CC-BY-NC' },
          { label: 'CC-BY-SA', value: 'CC-BY-SA' },
          { label: 'CC-BY-NC-SA', value: 'CC-BY-NC-SA' },
          { label: 'CC-BY-ND', value: 'CC-BY-ND' },
          { label: 'CC-BY-NC-ND', value: 'CC-BY-NC-ND' },
          { label: 'CC0', value: 'CC0' },
          { label: 'CC0', value: 'CC0' },
        ],
      }
    },
    watch: {
      '$route.path' (n) {
        this.$store.dispatch('readNode', { path: n })
        this.$store.dispatch('readObjects', { path: n })
      },
      'editedItem.template' (n) {
        if (n) {
          const f = this.templates.filter(e => e.id === this.editedItem.template)[0].value
          console.log(f, 'tpl updated')
          this.editedItem = { ...f, ...this.editedItem }
        }
        console.log(n)
      },
      'editedItem.type' (n) {
        if (n) {
          this.editedItem.template = n
        }
      },
      'objectList' () {
        this.entries = this.objectList
      },
      'editIndex' (n) {
        if (n === -1) {
          this.editedItem = {}
        } else {
          console.log(this.editedItem.template)
          console.log(n)
        }
      },
      '$route.query.object' (n) {
        if (this.$router.currentRoute.query.object) {
          this.$store.dispatch('readObject', { id: n })
        } else {
          this.$store.dispatch('readObjects', { path: this.$router.currentRoute.path })
        }
      },
      deep: true,
    },
    created () {
      this.$store.dispatch('readObjects', { path: this.$router.currentRoute.path })
    },
    mounted () {
      this.$store.dispatch('readNode', { path: this.$router.currentRoute.path })
      this.$store.dispatch('readObjects', { path: this.$router.currentRoute.path })
      if (this.$router.currentRoute.query.object) {
        this.$store.dispatch('readObject', { id: this.$router.currentRoute.query.object })
      }
    },
    methods: {
      setHasPendingUploads (val) {
        console.log('has uploads ', val)
        this.hasPendingUpload = val
      },
      updateInput (e) {
        this.setSelection(e.val, e.name)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      async getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          const {
            sortBy,
            descending,
            page,
            rowsPerPage,
            itemsPerPage,
          } = this.pagination

          const search = this.search.trim().toLowerCase()
          const params = {
            title: search,
            page: this.pagination.page - 1,
            size: itemsPerPage,
          }
          this.$store.dispatch('search/get', params)
            .then(() => {
              let items = this.objectList
              if (search) {
                items = items.filter(item => {
                  return Object.values(item)
                    .join(',')
                    .toLowerCase()
                    .includes(search)
                })
              }

              if (this.pagination.sortBy) {
                items = items.sort((a, b) => {
                  const sortA = a[sortBy]
                  const sortB = b[sortBy]

                  if (descending) {
                    if (sortA < sortB) return 1
                    if (sortA > sortB) return -1
                    return 0
                  } else {
                    if (sortA < sortB) return -1
                    if (sortA > sortB) return 1
                    return 0
                  }
                })
              }

              if (rowsPerPage > 0) {
                items = items.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage,
                )
              }

              const total = parseInt(this.searchTotalCount)

              setTimeout(() => {
                this.loading = false
                resolve({
                  items,
                  total,
                })
              }, 0)
            })
            .catch((e) => {
              console.log(e)
            })
        })
      },
      setSelection (value, field = 'authors') {
        this.editedItem[field] = value
      },
      editItem (item) {
        this.reloadKey += 1
        this.editedItem = {}
        this.editedIndex = this.entries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.template = item.type
        this.dialog = true
      },
      showItem (item) {
        this.$router.push({
          query: {
            object: item.id,
          },
        })
      },

      deleteItem (item) {
        this.editedIndex = this.entries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.entries.splice(this.editedIndex, 1)
        this.$store.dispatch('deleteObject', this.editedItem, this.typeItem)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
          this.editedIndex = -1
        })
      },
      async save () {
        if (!this.$refs.form.validate()) {
          Vue.toasted.error('Bitte prüfe die Formular-Eingaben').goAway(2000)
          return
        }
        if (this.typeItem === 'persons') {
          delete this.editedItem.name
        } else if (this.typeItem === 'organizations') {
          delete this.editedItem.firstName
          delete this.editedItem.lastName
        }
        this.editedItem.type = this.typeItem
        if (this.editedIndex > -1) {
          Object.assign(this.entries[this.editedIndex], this.editedItem)
          await this.$store.dispatch('updateObject', this.editedItem)
          await this.$store.dispatch('readObjects', { path: this.$router.currentRoute.path })
        } else {
          this.editedItem.displayName = this.editedItem.name || this.editedItem.title
          delete this.editedItem.name
          this.editedItem.type = this.editedItem.template || this.editedItem.type
          delete this.editedItem.template
          if (!this.editedItem.nodepath || this.editedItem.nodepath === '') {
            this.editedItem.nodepath = this.$router.currentRoute.path.replace('/nodes/', '/')
          }

          if (!this.editedItem.id || this.editedItem.id === '') {
            delete this.editedItem.id
            await this.$store.dispatch('createObject', {
              path: this.editedItem.nodepath,
              payload: this.editedItem,
            }).then(async res => {
              console.log(res)
              await this.$router.push({ query: { object: res.data.id } })
            }).finally(() => {
              this.$store.dispatch('readObject', { id: this.$router.currentRoute.query.object })
            })
          } else if (this.editedItem.id) {
            await this.$store.dispatch('updateObject', this.editedItem)
            await this.$store.dispatch('readObjects', { path: this.$router.currentRoute.path })
          }
        }
        this.close()
      },
    },
  }
</script>
