<template>
  <v-card
    class="pa-5 mt-5"
    outlined
  >
    <v-form
      id="upload_form"
      enctype="multipart/form-data"
      method="post"
      @submit="sendFile($event)"
    >
      <v-row v-if="uploadedFiles.length > 0">
        <v-col>
          <div class="preview-files">
            {{ uploadedFiles }}
          </div>
        </v-col>
      </v-row>
      <v-file-input
        ref="inputFile"
        multiple
        :label="label || 'Upload'"
        @change="setFileToUpload"
      />
      <v-row v-if="previewFiles.length > 0">
        <v-col>
          <v-progress-linear
            id="progressBar"
            ref="progressBar"
            :value="progressValue"
            max="100"
            style="width: 100%; max-width: 100%"
          />
          <h3 id="status">
            {{ status }}
          </h3>
          <p id="loaded_n_total">
            {{ progressText }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(prev, idx) in previewFiles"
          :key="idx"
          cols="3"
        >
          <img
            :key="idx"
            :src="prev.thumb"
            :title="prev.name"
          >
          <small>{{ prev.name + ' ' + prev.size + ' MB' }}</small>
        </v-col>
      </v-row>
      <v-btn
        class="mt-5"
        type="submit"
        color="primary"
      >
        Upload
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Upload',
    props: ['value', 'label'],
    data () {
      return {
        status: '',
        progressText: '',
        progressValue: 0,
        fileId: undefined,
        uploadedFiles: [],
        previewFiles: [],
      }
    },
    created () {
      if (this.value && this.value.length > 0) {
        this.uploadedFiles.push(...this.value)
      }
    },
    methods: {
      setFileToUpload () {
        const elem = this.$refs.inputFile.$el.querySelector('input')
        if (elem.files.length > 0) {
          for (let i = 0; i < elem.files.length; i++) {
            try {
              this.generateThumbnail(elem.files[i], [300, 300]).then(res => {
                this.previewFiles.push({
                  thumb: res,
                  name: elem.files[i].name,
                  size: (elem.files[i].size / 1000000).toFixed(2),
                })
              })
            } catch {
            }
          }
          this.$emit('hasUploads', true)
        } else {
          this.$emit('hasUploads', false)
        }
      },
      async sendFile (e) {
        e.preventDefault()
        const file = this.$refs.inputFile.$el.querySelector('input')?.files
        console.log(file)
        if (file.length === 1) {
          const f = file[0]
          await this.upload(f)
        } else if (file.length > 1) {
          for (let i = 0; i < file.length; i++) {
            await this.upload(file[i])
          }
        }
      },
      generateThumbnail (file, boundBox) {
        if (!boundBox || boundBox.length !== 2) {
          // eslint-disable-next-line no-throw-literal
          throw 'You need to give the boundBox'
        }
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) {
          throw new Error('Context not available')
        }

        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onerror = reject
          img.onload = function () {
            const scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
            const w = img.width * scaleRatio
            const h = img.height * scaleRatio
            canvas.width = w
            canvas.height = h
            ctx.drawImage(img, 0, 0, w, h)
            return resolve(canvas.toDataURL(file.type))
          }
          img.src = window.URL.createObjectURL(file)
        })
      },
      async upload (f) {
        const onUploadProgress = (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          if (percent < 100) {
            this.progressValue = percent
            this.progressText = percent + '% uploaded... please wait'
          } else {
            this.progressValue = percent
            this.progressText = percent + '% uploaded... Done! '
          }
        }

        const fd = new FormData()
        fd.append('myFile', f)
        fd.append('name', 'myFile')
        fd.append('filename', f.name)
        await axios.post(
          `${process.env.VUE_APP_API_FILES}`,
          fd,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJpc3MiOiJhdXRoLmhnay5maG53LmNoXC9oZ2tmb3JtcyIsIm5iZiI6MTYxNjYwMzgzNiwiaWF0IjoxNjE2NjAzODM2LCJleHAiOjE3MTY2MDAyNDAsInVzZXJJZCI6IjEiLCJlbWFpbCI6ImdyYXppYW5vLnZvbmFsbG1lbkBmaG53LmNoIiwiZmlyc3ROYW1lIjoiR3Jhemlhbm8iLCJsYXN0TmFtZSI6InZvbiBBbGxtZW4iLCJob21lT3JnIjoiZmhudy5jaCIsImdyb3VwcyI6ImZobndcL2hnaztmaG53XC9zdGFmZjtmaG53XC91c2VyO2dsb2JhbFwvZGlnbWE7Z2xvYmFsXC9ndWVzdDtnbG9iYWxcL3N0YWZmO2dsb2JhbFwvdXNlcjtoZ2tcL2JhO25ldFwvZmhudyJ9.660hLy9HxugAJyE2-aezY91NfZK2VZ6ByKo9IEaEhLeaV3wgNztF2bWsQGmBewYT',
            },
            onUploadProgress,
          },
        ).then(async (response) => {
          const fId = await response.data
          this.fileId = fId
          this.uploadedFiles.push(fId)
        }).catch((e) => {
          console.error(e)
        }).finally(() => {
          this.$emit('selection', this.uploadedFiles)
          this.$emit('hasUploads', false)
        })
      },
    },
  }
</script>
