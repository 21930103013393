<template>
  <div class="pt-2">
    <div
      class="inner-form"
      style="display: flex; flex-flow: row wrap"
    >
      <template v-for="(item, idx) in fields">
        <component
          :is="fieldTypes[item].type || 'string'"
          v-if="fieldTypes[item] && fieldTypes[item].type !== 'custom'"
          :key="idx"
          :style="fieldTypes[item] && fieldTypes[item]['x-style'] ? fieldTypes[item]['x-style'] : 'flex: 1 0 100%'"
          :label="fieldTypes[item] && fieldTypes[item].label || item"
          :info="fieldTypes[item] && fieldTypes[item].info || null"
          :required="fieldTypes[item] && fieldTypes[item].required || false"
          :disabled="fieldTypes[item] && fieldTypes[item].disabled || false"
          :options="fieldTypes[item] && fieldTypes[item].options || []"
          :hidden="fieldTypes[item] && fieldTypes[item].hidden || false"
          :value="loadedSelection[item] || null"
          :name="item"
          :return-object="fieldTypes[item] && fieldTypes[item].options && fieldTypes[item].options[0] && fieldTypes[item].options[0].title || false"
          @change="change"
        />
        <slot
          v-else
          :props="{name: item, type: fieldTypes[item].type, label: fieldTypes[item].label }"
        >
          <pre :key="idx">
          {{ item }}
          {{ fieldTypes[item] }}
        </pre>
        </slot>
      </template>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    components: {
      string: () => import('@/components/inputs/Textfield'),
      longtext: () => import('@/components/inputs/Textarea'),
      bool: () => import('@/components/inputs/Checkbox'),
      array: () => import('@/components/inputs/Array'),
      dropdown: () => import('@/components/inputs/Dropdown.vue'),
    },
    props: ['template', 'ui', 'loadedSelection'],
    data () {
      return {
        fields: [],
        fieldTypes: [],
      }
    },
    async created () {
      const tpl = await axios.get(this.template)
      const ui = await axios.get(this.ui)
      const uiParsed = JSON.parse(JSON.stringify(ui.data))
      this.fields = Object.keys(tpl.data)
      this.fieldTypes = uiParsed
    },
    methods: {
      change (e) {
        this.$emit('update', { val: e.val, name: e.name })
      },
    },

  }
</script>
